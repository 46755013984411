import type WaitingZoneNumbersData from "~/api/response/data/checkpoint/WaitingZoneNumbersData";

export default class WaitingZoneNumbers {
    liveNumber: number;
    priorityNumber: number;

    constructor(data: WaitingZoneNumbersData) {
        this.liveNumber = data.live_number || 0;
        this.priorityNumber = data.priority_number || 0;
    }
    get totalNumber() {
        return this.liveNumber + this.priorityNumber;
    }
}