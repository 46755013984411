import {defineStore} from "pinia";
import CheckpointSummary from "~/dto/checkpoint/Summary";
import type CheckpointSummaryResponse from "~/api/response/CheckpointsSummaryResponse";
import type CheckpointSummaryData from "~/api/response/data/checkpoint/SummaryData";
import _ from "lodash";
import type WaitingZoneNumbersData from "~/api/response/data/checkpoint/WaitingZoneNumbersData";

type CheckpointsSummaryData = Record<string, CheckpointSummaryData>;

export const useCheckpointsSummaryStore = defineStore('checkpoints', () => {
    const checkpointsSummaryData = ref<CheckpointsSummaryData>({});
    const checkpointsSummary = computed<CheckpointSummary[]>(() => {
        return _.map(checkpointsSummaryData.value, (data: CheckpointSummaryData) => new CheckpointSummary(data));
    });
    const openCheckpointsSummary = computed<CheckpointSummary[]>(() => {
        return _.filter(checkpointsSummary.value, (summary: CheckpointSummary) => summary.isOpen);
    });
    const checkpointsSummaryPending = ref<boolean>(false);

    function setCheckpointsSummaryData (data: CheckpointSummaryData[]) {
        checkpointsSummaryData.value = _.keyBy(data || [], 'code');
    }

    async function fetch () {
        if (_.size(checkpointsSummaryData.value)) {
            return checkpointsSummaryData.value;
        }

        checkpointsSummaryPending.value = true;
        try {
            const { data } = await apiFetch<CheckpointSummaryResponse>('/checkpoints/summary');
            setCheckpointsSummaryData(data);
        } catch (error) {
            captureException(error);
        }
        checkpointsSummaryPending.value = false;

        return checkpointsSummaryData.value;
    }

    function updateCheckpointWaitingZoneNumbersData (code: string, data: WaitingZoneNumbersData) {
        if (checkpointsSummaryData.value[code]) {
            checkpointsSummaryData.value[code].waiting_zone_numbers = data;
        }
    }

    function getCheckpointData (code: string) {
        return _.find(checkpointsSummaryData.value, {code: code}) || null;
    }

    if (process.client) {
        const { echoChannel } = useEcho();
        echoChannel("checkpoints")
            .listen('.checkpoints-summary.refresh', function (data: {checkpoints_summary: CheckpointSummaryData[]}) {
                setCheckpointsSummaryData(data.checkpoints_summary);
            });
    }

    return {
        fetch,
        updateCheckpointWaitingZoneNumbersData,
        getCheckpointData,
        checkpointsSummary,
        checkpointsSummaryData,
        checkpointsSummaryPending,
        openCheckpointsSummary,
    };
});
