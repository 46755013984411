import type CheckpointSummaryData from "~/api/response/data/checkpoint/SummaryData";
import CheckpointTotal from "~/dto/checkpoint/Total";
import WaitingZoneNumbers from "~/dto/checkpoint/WaitingZoneNumbers";
import {CheckpointStatus} from "~/dto/Checkpoint";

export default class CheckpointSummary {
    code: string;
    name: string;
    status: CheckpointStatus;
    countryCode: string;
    total: CheckpointTotal | null;
    waitingZone: boolean;
    waitingZoneNumbers: WaitingZoneNumbers | null;
    waitingZoneDuration: number | null;

    constructor(data: CheckpointSummaryData) {
        this.code = data.code;
        this.name = data.name;
        this.status = data.status as CheckpointStatus;
        this.countryCode = data.country_code;
        this.total = data.total ? new CheckpointTotal(data.total) : null;
        this.waitingZone = data.waiting_zone;
        this.waitingZoneNumbers = data.waiting_zone_numbers ? new WaitingZoneNumbers(data.waiting_zone_numbers) : null;
        this.waitingZoneDuration = data.waiting_zone_duration || null;
    }

    get isOpen() {
        return CheckpointStatus.Open === this.status;
    }

    get isClosed() {
        return CheckpointStatus.Closed === this.status;
    }
}