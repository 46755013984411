import type CheckpointTotalData from "~/api/response/data/checkpoint/TotalData";
import { DateTime } from "luxon";

export default class CheckpointTotal {
    number: number;
    date: DateTime;

    constructor(data: CheckpointTotalData) {
        this.number = data.number;
        this.date = DateTime.fromISO(data.date);
    }
}